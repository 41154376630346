import React from 'react';
import BusinessCard from './components/BusinessCard';

function App() {
  const businessCardData = {
    fullName: 'Chizaram Chibueze',
    jobTitle: 'Founder / CEO',
    phoneNumber: '929-353-1578',
    emailAddress: 'chizy@chizyig.com',
    xProfile: 'x.com/chizyization',
    qrCodeUrl: `${process.env.PUBLIC_URL}/chizyQR.png`,
  };

  return (
    <div className="App">
      <BusinessCard {...businessCardData} />
    </div>
  );
}

export default App;
