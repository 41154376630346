import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QrCode, Phone } from 'lucide-react';
import profilePicture from '../assets/pfp01.jpg';

const COMPANY_NAME = 'CHIZY INVESTMENT GROUP';
const COMPANY_WEBSITE = 'www.chizyig.com';
const LOGO_PATH = '/logo512.png';

const Popup = ({ children, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
    <div className="bg-white rounded-2xl w-full max-w-xs shadow-lg animate-slide-up-fast">
      <div className="p-6 space-y-4">{children}</div>
      <div className="border-t border-gray-200">
        <button
          onClick={onClose}
          className="w-full py-4 text-gray-600 font-medium text-base"
        >
          Done
        </button>
      </div>
    </div>
  </div>
);

const BusinessCard = ({
  fullName = 'name surname',
  jobTitle = 'job title',
  phoneNumber = '000-000-0000',
  emailAddress = 'name@email.com',
  xProfile = 'x.com/username',
  website = COMPANY_WEBSITE,
  qrCodeUrl = 'https://example.com/qr',
}) => {
  const [activePopup, setActivePopup] = useState(null);

  const openPopup = (popupType) => setActivePopup(popupType);
  const closePopup = () => setActivePopup(null);

  const contactInfo = [
    { label: 'Phone', value: phoneNumber, href: `tel:${phoneNumber}` },
    { label: 'Email', value: emailAddress, href: `mailto:${emailAddress}` },
    { label: 'X.com', value: 'View Profile', href: `https://${xProfile}` },
    { label: 'Website', value: website, href: `https://${website}` },
  ];

  const actionButtons = [
    { icon: QrCode, label: 'Scan QR', action: () => openPopup('qr') },
    { icon: Phone, label: 'Show Contact', action: () => openPopup('contact') },
  ];

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-80 bg-white rounded-2xl shadow-lg overflow-hidden relative">
        <div className="relative">
          <img
            src={profilePicture}
            alt={fullName}
            className="w-full h-60 object-cover"
          />
          <img
            src={LOGO_PATH}
            alt={COMPANY_NAME}
            className="absolute top-12 left-11 h-10 w-auto"
          />
        </div>
        <div className="p-4 pb-10">
          <div className="bg-white rounded-2xl shadow py-6 px-4 absolute top-48 left-8 right-8">
            <div>
              <h2 className="text-xl font-bold text-gray-800">{fullName}</h2>
              <p className="text-sm text-gray-600">{jobTitle}</p>
            </div>
            <div className="my-2 border-t border-gray-200"></div>
            <div>
              <p className="text-sm text-gray-600">{COMPANY_NAME}</p>
            </div>
          </div>
          <div className="space-y-2 mt-20 px-4">
            {actionButtons.map(({ icon: Icon, label, action }) => (
              <button
                key={label}
                onClick={action}
                className="flex items-center justify-center bg-gray-200 text-gray-800 w-full py-2 rounded-lg"
              >
                <Icon size={16} className="mr-2" />
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {activePopup === 'qr' && (
        <Popup onClose={closePopup}>
          <div className="w-full aspect-square bg-gray-100 flex items-center justify-center rounded-lg">
            <img
              src={qrCodeUrl}
              alt="QR Code"
              className="max-w-full max-h-full"
            />
          </div>
        </Popup>
      )}

      {activePopup === 'contact' && (
        <Popup onClose={closePopup}>
          <div className="space-y-4">
            {contactInfo.map(({ label, value, href }) => (
              <div key={label} className="flex justify-between items-center">
                <span className="text-gray-800 text-base">{label}</span>
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 text-base"
                >
                  {value}
                </a>
              </div>
            ))}
          </div>
        </Popup>
      )}
    </div>
  );
};

BusinessCard.propTypes = {
  fullName: PropTypes.string,
  jobTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  xProfile: PropTypes.string,
  website: PropTypes.string,
  qrCodeUrl: PropTypes.string,
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BusinessCard;
